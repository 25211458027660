<template>
  <div style="background: #eeeeee" class="app">
    <toubu></toubu>
    <BDhear :num="2"></BDhear>
 <div id="handout_wrap_inner"></div>
    <div class="ciona" v-if="centerDialogVisible"></div>
    <div style="" v-if="tuibao">
      <div class="app-xt">
        <div class="app-a"></div>
        <div class="app-b">
          <div class="juzhong">
            <img src="@/assets/xin/pdf (2).png" class="wimg" alt="" />
          </div>
          <!-- <div style="" class="bacl1-tets">出函成功！</div> -->
          <div class="bacl1-tets">
              退保成功！
            </div>
          <div style="margin: 0 10%" class="bacl1-tet">
            尊敬的电子保函用户，您好！为保障您的信息安全，对于您所生成的电子保函，进行了加密处理，请悉知！
            （注：加密信息包含项目标段信息和招标人信息，待开标时间后进行解密，生成同一份明文的电子保函）
          </div>
          <div style="" class="bacl1">
            <div class="public-kuang">
              <div class="kuang-txt hang">
                <img
                  src="@/assets/xin/pdf (3).png"
                  alt=""
                  class="pdf"
                  srcset=""
                />
                <div class="kuang-1 hang">
                  <!-- <span>密文保函</span> -->
                <div class="contzis">
                 {{baohanname.biddername}}
                </div>
                </div>
              </div>
              <div class="woxin ieright">
                <div class="fudon">
                  <img
                    src="@/assets/xin/pdf (1).png"
                    alt=""
                    class="pdf"
                    srcset=""
                  />
                </div>
                <div
                  style="margin-left: 10px"
                  class="kuang-2 ieleft fudon"
                 
                >
                  <!-- <img
                    src="@/assets/xin/pdf (5).png"
                    class="xiaimg"
                    alt=""
                    srcset=""
                  /> -->
                  <span>已作废</span>
                </div>
                <!-- <div
                  style="margin-left: 10px"
                  class="kuang-2 ieright fudons"
                 
                >
                  <img
                    src="@/assets/xin/pdf (4).png"
                    class="xiaimg"
                    alt=""
                    srcset=""
                  />
                  <span>已作废</span>
                </div> -->
                <div class="contzi">
                  投标保函凭证【密文】（{{baohanname.applyno}}）
                </div>
              </div>
              <div v-if="false" class="xihuan" style="">
                <!-- <div>保函文件：{{ wenname }}</div> -->

                <div style="margin-top: 10px">
                  下载地址：
                  <a :href="filepath" target="_blank">{{ filepath }}</a>
                </div>
                <div></div>
              </div>
            </div>
          </div>
          <div class="b-bbc">
            <img
              class="bbcimg"
              src="@/assets/b82318b0e0b31ec62ce6393cc7f72dc.png"
              alt=""
              srcset=""
            />
            <!-- <div
              style="
                font-size: 16px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #666666;
              "
            >
              南阳市公共资源交易平台
            </div> -->
          </div>

          <div class="c-txt">
            <img
              src="@/assets/cshen.png"
              alt=""
              style="width: 100%"
              srcset=""
            />
            <!-- <div class="c-txt1"></div>
            <div class="c-txt2">申请详情</div>
            <div class="c-txt3"></div> -->
          </div>
          <!-- </el-card> -->
          <div class="baohan">
            <div
              style="
                width: 100%;
                font-size: 16px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #666666;
              "
            >
              <div>
                <div class="input-flex">
                  <div class="flex-txt1 uppder">
                    <span class="flex-txt"></span>申请编号：{{
                      baohanname.baohanno
                    }}
                  </div>
                </div>
                <div class="input-flex">
                  <div class="flex-txt1 uppder">
                    <span class="flex-txt"></span>保函编号：{{
                      baohanname.applyno
                    }}
                  </div>
                </div>
                <div class="input-flex">
                  <div
                    style="
                      overflow: hidden;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                    "
                    class="flex-txt1 uppder"
                  >
                    <span class="flex-txt"></span>保函产品：{{
                      baohanname.baohanname
                    }}
                  </div>
                </div>
                <div class="input-flex">
                  <div class="flex-txt1 uppder">
                    <span class="flex-txt"></span>出函时间：{{
                      baohanname.generatetime | dateFormat
                    }}
                  </div>
                </div>
              </div>
              <div class="input-flex">
                <div class="flex-txt1 uppder">
                  <span class="flex-txt"></span>保证金额：{{
                    baohanname.cost
                  }}元
                </div>
              </div>
              <div class="input-flex">
                <div class="flex-txt1 uppder">
                  <span class="flex-txt"></span>申请时间：{{
                    baohanname.createtime | dateFormat
                  }}
                </div>
              </div>
            </div>
          </div>
          <div class="butt zi">
            <button v-if="bajsd" @click="entryUserInfo" class="button-i zi">
              保函已退保
            </button>
            <!-- <div v-else @click="tiahsd" class="button-i">{{ tijiao }}</div> -->
          </div>
        </div>
      </div>
    </div>
    <div v-else class="app-bbb">
      <div class="">
        <el-card class="box-card">
          <div class="text item">当前保单已退保</div>
        </el-card>
      </div>
    </div>
    <div v-if="false" class="asdding">
      <div class="asdzong">
        <div style="margin-top: 5px">联系我们</div>
        <div
          style="
            margin-top: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
          "
        >
          <div @mouseover="chuxian(1)" @mouseout="yincang(1)">
            <img
              src="../../assets/login/tang.png"
              style="width: 60px; height: 60px"
              class="asdimgh"
              alt=""
              srcset=""
            />
          </div>
          <div style="font-size: 14px">业务联系人：唐先生</div>
          <div style="font-size: 14px">联系电话：13140161000</div>
        </div>
        <div
          style="
            margin-top: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
          "
        >
          <div @mouseover="chuxian(2)" @mouseout="yincang(2)">
            <img
              src="../../assets/login/zhang.png"
              style="width: 60px; height: 60px"
              class="asdimgh"
              alt=""
              srcset=""
            />
          </div>
          <div style="font-size: 14px">技术联系人：张先生</div>
          <div style="font-size: 14px">联系电话：13838271800</div>
        </div>
        <div v-if="tang" style="position: absolute; right: 100%" class="block">
          <img style="width: 300px" src="../../assets/login/tang1.png" />
        </div>
        <div v-if="zhang" style="position: absolute; right: 100%" class="block">
          <img style="width: 300px" src="../../assets/login/zhang1.png" />
        </div>
      </div>
    </div>

    <dinad></dinad>
  </div>
</template>

<script>
import {
  bhtypelist,
  applyprogress,
  entryUserInfo,
  bhprocess,
} from "@/api/user";
import BDhear from "../../components/BDhear.vue";
// import $ from "jquery";

import dinad from "../../components/dinad.vue";
import toubu from "../../components/toubu.vue";
import {
  companybhlist,
  companyst,
  restorenotice,
  baohannotice,
} from "@/api/user";
import {
  createcontract,
  filesign,
  bhfiledown,
  snfiledown,
  bhinfo,
} from "@/api/user";

export default {
  components: {
    dinad,
    BDhear,
    toubu,
  },
  data() {
    return {
      input: {
        applyno: "TBaoHanXDCS2022071512241849470",
        unify_code: "",
        zfimage: "",
        bankname: "",
        gongsi: "暂无",
        // bankcode: "123",
        bankaccount: "",
        // jbrname:"",
        // bankno: "",
      },
      centerDialogVisible: false,
      quan: false,
      active: 0,
      bankaccount: "",
      tong: true,
      tongbu: "同步资料中",
      tabPosition: "right",
      tab: "left",
      dialogImageUrl: "",
      cion: "",
      dialogVisible: false,
      unify_code: "",
      imageUrl: "",
      applyno: "",
      tijiao: "提交支付",
      timer: null, // 定时器名称
      bajsd: true,
      state: "",
      tuibao: true,
      truesss: false,
      jbrphone: "",
      payer: "",
      indexs: "正在审核中",
      statemsg: "",
      filepath: "未获取", //密文
      mfilepath: "未获取", //明文
      epath: "https://www.hnzyxsbh.com",
      xianshi2: true,
      applyno: "",
      namses: "获取",
      panduan: "",
      mfile: "",
      wenname: "未获取",
      mwenname: "未获取",
      baohanname: {},
    };
  },
  async created() {
    // var apps = "TBaoHanHNYZTBBH2022072717222050537";
    var bbc = this.applyno;
// import('@/config/jquery.media.js')
    var bbc = sessionStorage.getItem("unify_code");
    this.unify_code = bbc;
    this.input.unify_code = bbc;

    // this.input.baohanno = this.$route.params.bao;
    this.input.applyno = sessionStorage.getItem("applyno");
    this.applyno = sessionStorage.getItem("applyno");
    // const { data } = await bhprocess(this.applyno);
    // console.log("保函详情",data.data)
    // var baohanname=data.data
    this.bhproces();
    this.bhinfos();
    // this.asjhdja(); //判断url是否存在
    // this.bhtypelist(); //银行数据

    // this.tiao();
  },
  methods: {
    async bhinfos() {
      var that = this;
      var cion = sessionStorage.getItem("baohanno");
      const { data } = await bhinfo(cion); //保函详情
      console.log("保函详情", data);
      this.baohanname = data.data;
    },
    async bhproces() {
      // console.log(132);
      var that = this;
      var cion = this.applyno;
      const { data } = await bhprocess(cion); //获取状态
      // console.log(data);

      // "ztype=>0:密文，1:等待还原，2:还原完成;billstatus=>0:未领取,1:申请中,2:已发放"
      var cd = data.data.billstatus; //开票状态
      // var zt=null
      this.wenname = data.data.filepath;
      var vvc = data.data.mfilepath;
      if (data.data.mfilepath !== null) {
        var bbc = vvc.split("/");
        this.mwenname = bbc[4];
        this.mfilepath = this.epath + data.data.mfilepath; //明文
      }

      this.wenname = this.wenname.split("/")[4];
      this.filepath = this.epath + data.data.filepath; //pdf
      var mmfilepath = this.epath + data.data.mfilepath; //明文
      this.mfile = data.data.mfilepath;
      this.panduan = data.data.mfilepath;
      this.ztype = data.data.ztype; //保函
      if (this.ztype === 0) {
        this.namses = "等待开标";
        this.mingwen = true;
      }
      if (this.ztype === 1) {
        this.namses = "获取";
        this.mingwen = true;
      }
      if (this.ztype === 2) {
        this.namses = "下载";
        this.smingwen = true;
        this.mingwen = true;
        this.pand();
      }

      if (cd === null || cd === 0) {
        this.dian = true;
        // this.entryUserInfo();
        return;
      }
      if (cd === 1) {
        this.dian = false;
      }
      if (cd === 2) {
        this.dian = false;
        this.tongbus = "已发送";
        return;
      }
    },
    yxiazai() {
      let link = document.createElement("a");
      link.style.display = "none";
      // link.style.download = "";
      link.target = "_blank";
      link.href =
        "https://www.hnzyxsbh.com//uploads/dbfiles/20221123/BH_202211231669187763.pdf";
      // link.setAttribute("download", ".pdf");
      // +'.pdf' 通过不同的后缀名下载不同的文件this.filepath
      //这里下载的是pdf格式的文件，拿到接口里的res也必须是pdf的文件流
      // document.body.appendChild(link);
      link.click();
      // URL.revokeObjectURL(link.href); //URL.revokeObjectURL() 静态方法用来释放一个之前通过调用 URL.createObjectURL() 创建的已经存在的 URL 对象。当你结束使用某个 URL 对象时，应该通过调用这个方法来让浏览器知道不再需要保持这个文件的引用了。
      // document.body.removeChild(link);
      // console.log("密文预览");
    },
    xiazais() {
      var iframes = document.createElement("iframe");
      iframes.src = this.filepath;
      iframes.style.display = "none";
      document.body.appendChild(iframes);
      return;
    },
    async xiazai() {
      var iframe = document.createElement("iframe");
      iframe.src = this.filepath;
      iframe.style.display = "none";
      document.body.appendChild(iframe);
      return;
      let url = this.filepath; //  download(url)
      var bbc = {
        applyno: this.applyno,
        type: 0,
      };
      const { data } = await snfiledown(bbc);
      this.xiazpdf(data.data);
    },
    // xin

    async bhtypelist() {
      var cion = this.input.applyno;
      var cin = this.unify_code;
      var bbc = {
        cion,
        cin,
      };

      const { data } = await bhtypelist(bbc);
      this.cion = data.data;
    },
    async asjhdja() {
      var bbc = this.applyno;
      const { data } = await bhprocess(bbc);
      this.msg = data.msg;
      this.state = data.data.state;
      if (this.state == 2) {
        this.tuibao = false;
        return;
      }
      var bimg = data.data.image;
      // if(this.state===0){
      //   this.bajsd=true
      // }
      if (data.data.image == null) {
        //url不存在不改变
        this.enstate();
        return;
      } else {
        // 存在则填充并调用下一步获取按钮状态
        this.imageUrl = bimg;
        // this.tijiao = "下一步";
        // this.bajsd = false;
        this.enstate();
      }
    },
    tiao() {
      var that = this;
      // console.log(123);
      if (this.unify_code == "") {
        that.$router.push({
          name: "project",
        });
      }
    },
    next(e) {
      // console.log(e);
      this.active++;
      // console.log(this.active);

      if (this.active++ >= 5) {
        (this.tong = false), (this.tongbu = "同步资料完成");
        this.active = 4;
      }
    },
    async tiahsd() {
      var bbc = this.applyno;
      const { data } = await bhprocess(bbc);
      if (data.data.state == 0) {
        this.$alert(data.data.statemsg, "提示", {
          confirmButtonText: "确定",
          customClass: "messageasd",
          callback: (action) => {
            this.enstate();
          },
        });
        // window.location.reload();
        true;
      } else {
        this.$router.push({
          name: "added",
          params: {},
        });
        //      this.$alert(this.statemsg, '提示', {
        //   confirmButtonText: '确定',
        //     customClass:"messageasd",
        //   callback: action => {

        //   }
        // });
      }
    },
    async entryUserInfo() {
      var bbc = {
        ...this.input,
      };
      return;
      // if(this.input.bankname==""){
      //    this.$notify({
      //   title: "提醒",
      //   message:"账号名称不能为空" ,
      // });
      // return
      // }else
      // if (this.input.bankaccount == "") {
      //   this.$notify({
      //     title: "提醒",
      //     message: "账户号码不能为空",
      //   });
      //   return;
      // } else if (this.bankcode == "") {
      //   this.$notify({
      //     title: "提醒",
      //     message: "开户银行不能为空",
      //   });
      //   return;
      // } else
      if (this.imageUrl == "") {
        this.$notify({
          title: "提醒",
          message: "请上传图片",
        });
        return;
      } else {
        //    this.$notify({
        //   title: "提醒",
        //   message: data.msg,
        // });
      }
      const { data } = await entryUserInfo(bbc);
      if (data.msg == "支付提交成功") {
        //  console.log(data);
        this.quan = true;
        this.enstate();
      } else {
        this.$alert(data.msg, "提示", {
          confirmButtonText: "确定",
          customClass: "messageasd",
          callback: (action) => {},
        });
      }
    },
    async enstate() {
      //判断是否需要支付
      var bbc = this.applyno;
      const { data } = await bhprocess(bbc);
      this.state = data.data.state; //审核状态
      this.statemsg = data.data.statemsg;
      // this.input.jbrname = data.data.payer; //付款人
      this.input.bankname = data.data.rebankname; //付款人
      this.input.bankaccount = data.data.rebankaccount; //付款人
      this.payer = data.data.payer;
      this.jbrphone = data.data.jbrphone;
      this.fpstate = data.data.fpstate; //审核状态
      var that = this;
      if (this.imageUrl == "") {
        return;
      }

      if (this.state == 0) {
        that.tijiao = "下一步";
        this.bajsd = false;
        return;
      }
      if (this.state == 1) {
        that.tijiao = "下一步";
        that.indexs = "审核已完成";
        this.bajsd = false;
      }
    },
    handleAvatarSuccess(res, file) {
      console.log(23);
      this.input.zfimage = file.response.data.fullurl;
      // console.log(file.response.data.fullurl);
      this.imageUrl = file.response.data.fullurl;
    },
    beforeAvatarUpload(file) {
      console.log(123456);
      const isJPG = file.type;
      const isLt2M = file.size;
      return isJPG && isLt2M;
    },

    project() {
      var bbc = this.input.baohanno;
      var cion = this.applyno;
      setInterval(function () {
        const { data } = bhprocess(cion);
        console.log(data);
        // this.$router.push({
        //   name: "public",
        //   params: {
        //     bbc,
        //   },
        // });
      }, 1000);
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
  },
};
</script>

<style scoped>
.asdzong {
  position: fixed;
  right: 20px;
  bottom: 10%;
  width: 200px;
  display: flex;
  border: 1px #c5c5c5 solid;
  border-top: 3px tomato solid;
  flex-direction: column;
  /* justify-content: center; */
  background: #fff;
  align-items: center;
  padding-bottom: 20px;
}
.wimg {
  width: 250px;
  height: 110px;
}
.asdimgh {
  width: 60px;
  height: 60px;
}
html {
  width: 100vw;
}
.znashi {
  margin-left: 5%;
  border: 0.0625rem solid #d9d9d9;
}
.bacl {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
  margin-top: 1rem;
}
.bacl1 {
  /* flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center; */
  margin: 0 10%;

  margin-bottom: 20px;
}
.bacl1-text {
  font-size: 22px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 700;
  color: #333333;
  padding-top: 30px;
  line-height: 0px;
}
.bacl1-tet {
  padding-top: 15px;
  font-size: 18px;
  /* height: 100px; */
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  text-align: center;
  line-height: 2rem;
  color: #e41c1c;
  margin: 0 10%;
  /* line-height: 0px; */
}
.quan {
  /* background: rgb(128, 128, 128, 1); */
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.app {
  /* padding: 0 20%; */
}
.app-xt {
  padding: 0 8%;
}
.b-bbc {
  /* margin: 0 auto; */
  margin: 0 10%;
  height: 100px;
  display: flex;

  text-align: center;
  line-height: 100px;
  justify-content: center;
  align-items: center;
  /* flex-direction: column; */
  /* padding: 0.5rem 0; */
  border: 0.1rem solid #d9d9d9;
  /* padding-right: 4.375rem; */
  margin-bottom: 1rem;
}
.inout-1 {
  padding: 0.9375rem 0rem;
  padding-top: 0rem;
}
.indec {
  display: inline-block;
  width: 26.25rem;
  padding: 3.125rem;
  margin-top: -15%;
  padding-bottom: 0.625rem;
  vertical-align: middle;
  background-color: #fff;
  border-radius: 0.25rem;
  border: 0.0625rem solid #ebeef5;
  font-size: 1.125rem;
  box-shadow: 0 0.125rem 0.75rem 0rgba (0, 0, 0, 0.1);
  text-align: left;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  padding-bottom: 1.875rem;
}
/* .csdff{
  width:100%
} */
.name {
  margin-bottom: 1.25rem;
}
.input {
  display: flex;
  /* justify-content: space-around; */
  /* align-items: center; */
  /* padding-right: 3rem; */
}
.name-jin {
  width: 100%;
  height: 100%;
  background: #dedede;
}
.uppder {
  padding-right: 0.75rem;
}
.input-flex {
  display: flex;
  margin-top: 0.625rem;
}
.input-1 {
  width: 90% !important;
  width: 7.0625rem;
  /* height: 1.25rem !important; */
}
.input-1s {
  width: 70% !important;

  /* height: 1.25rem !important; */
}
.fudon {
  float: left;
}
.bacl1-tets {
  padding-top: 15px;
  font-size: 20px;
  /* height: 100px; */
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 600;
  text-align: center;
  /* line-height:2rem; */
}
.app-h {
  padding-left: 27px;
  /* width: 100%; */
  height: 43px;
  line-height: 43px;
  background: #1890ff;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
}
/* .el-step .is-simple .el-step__title{
font-size: 20px !important;
} */
::v-deep .el-input__inner {
  margin-left: 0.875rem !important;
  padding: 0 !important;
  font-size: 18px !important;
  padding-left: 0.9375rem !important;
  height: 1.6875rem !important;
  line-height: 1.6875rem !important;
  color: #333333 !important;
  width: 90%;
}

.text {
  font-size: 0.875rem;
}

.item {
  padding: 0.625rem 0;
  padding-left: 0.625rem;
}

.box-card {
  width: 30rem;
}
.flex-txt {
  font-size: 0.875rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ff0101;
}
.flex-txt1 {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  /* width: 100%; */
  text-align: left;
}
.abc {
  /* padding-left: 1.5rem; */
}
.app-a {
  display: flex;
  /* margin-top: 28px; */
  position: relative;
}
.a-left {
  width: 50%;
  margin-top: 1px;
  height: 47px;
  display: flex;
  line-height: 50px;
  align-content: center;
  justify-content: center;
  color: #069cff !important;
  /* background: #cdebff; */
  border: 1px solid #069cff;
  /* border-right: 0; */
  /* border-radius: 9px 0px 0px 9px; */
  font-size: 17px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
}
.ciona {
  width: 0 !important;
  height: 0 !important;
  overflow: hidden;
}
.a-img {
  padding-top: 13.6px;
  padding-right: 15px;
  width: 20px;
  height: 20px;
}
.aa-img {
  position: absolute;
  height: 3.06rem;
  margin-top: 1px;
  padding: 0;
  left: 45%;
}
.a-right {
  display: flex;
  align-content: center;
  justify-content: center;
  line-height: 50px;
  width: 50%;
  height: 49px;
  margin-top: 0.0625rem;

  background: #069cff;
  /* border-radius: 7px; */
  font-size: 17px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
}
.app-b {
  font-size: 0.5625rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  margin-top: 20px;

  background: #ffffff;
  /* display: flex; */
  /* flex-wrap: wrap; */
  padding: 3% 6%;
  /* align-content: space-around;
  justify-content: space-between; */
}
.app-bbb {
  display: flex;
  justify-content: center;
  align-content: center;
  margin-top: 10%;
}
.ieright{
    float: right;
}
.hang{
    /* display: inline-block !important */
    display: inline !important;
}
.app-c {
  /* width: 100%; */
  padding: 0 10%;
  display: flex;
  /* margin-top: 30px; */
  flex-direction: column;
}
.app-b2 {
  display: flex;
  line-height: 24px;
  font-size: 13px;
  width: 48%;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  margin-bottom: 26px;
}
.b-txt {
  width: 296px;
  height: 24px;
  flex: 1;
  background: #f3f3f3;
  border: 1px solid #dedede;
  padding-left: 6px;
  line-height: 24px;
  font-size: 0.8125rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
}
.c-img {
  width: 30%;
  height: 100%;
}
.c-txt {
  font-size: 1.2rem;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;

  margin: 0 5%;
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.c-txt1 {
  width: 300px;
  height: 4px;
  background: linear-gradient(270deg, #999999 0%, rgba(153, 153, 153, 0) 100%);
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
}
.c-txt2 {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  width: 140px;
  font-weight: 600;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #333333;
}
.woxin {
  width: 60%;
  position: relative;
  /* display: flex;
  justify-content: space-between; */
  align-items: center;
}
.c-txt3 {
  width: 300px;
  height: 4px;
  background: linear-gradient(90deg, #999999 0%, rgba(153, 153, 153, 0) 100%);
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
}
.txt-1 {
  font-size: 13px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}
.txt-2 {
  margin-top: 17px;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}
.txt-3 {
  margin-top: 12px;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}
.txt-4 {
  margin-top: 15px;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  display: flex;
}
.c-div {
  margin-top: 4px;
  display: flex;
  flex-wrap: wrap;
  align-content: space-around;
  justify-content: space-between;
}
.butt {
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
}
.button-i {
  margin-top: 48px;
  margin-bottom: 10px;
  width: 24%;
  height: 5.5%;
  border: 0;
  padding: 6px 0;
  line-height: 30px;
  /* border-radius: 10px; */
  background: #069cff;
  text-align: center;
  font-size: 17px;
  font-family: Microsoft YaHei;
  font-weight: 550;
  color: #fefefe;
}
.b-bbc {
  /* padding: .625rem;
  border:.0625rem solid #dfdcdc; */
}
/* 上传 */
.avatar-uploader .el-upload {
  border: 0.0625rem dashed #d9d9d9;
  border-radius: 0.375rem;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader {
  border: 0.0625rem solid #d9d9d9;
}
.xihuan {
  margin-top: 15px;
  font-size: 0.95rem;
}
.public-kuang {
  margin-top: 25px;
  box-sizing: border-box;

  margin-bottom: 20px;
  width: 100%;
  height: 100px;
  padding: 1rem 1rem;
  padding-left: 10%;
  /* display: flex;
  flex-direction: column; */
  /* justify-content: space-between; */
  /* align-items: center; */
  background: #ffffff;
  border: 0.1rem solid #d9d9d9;
  /* box-shadow: 0rem 0rem 0.1875rem 0rem rgb(0 0 0 / 15%); */
}
.pdf {
  width: 60px;
  height: 60px;
}
.kuang-txt {
  /* display: flex;
  justify-content: center;
  align-items: center; */
  font-weight: 800;
  width: 40%;
}
.kuang-2 {
  /* 
  width: 4.125rem;
  height: 1.375rem;
  line-height: 1.375rem;
  text-align: center;

  background: #069cff;
  border: 0.0625rem solid #069cff;
  font-size: 1rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  padding: 0.3125rem 0.5125rem;
  color: #fff; */
  cursor:not-allowed;
  display: inline-block;
  vertical-align: middle;
  width: 100px;
  height: 25px;
  background: #ffffff;
  border-radius: 500px 500px 500px 500px;
  opacity: 1;
  color: #1890ff;
  font-size: 15px;
  text-align: center;
  border: 2px solid #1890ff;
  line-height: 25px;
}
.xiaimg {
  width: 1rem;
  height: 1rem;
  margin-right: 5px;
  vertical-align: -0.15em;
  /* vertical-align: middle; */
}
.pdfyu {
  width: 200px;
  height: 300px;
}
.kuang-1 {
  position:relative;
  font-size: 1.1625rem;
  line-height: 2.3125rem;
  margin-left: 1.25rem;
  margin-top: 0.375rem;
  font-family: Microsoft YaHei;
  font-weight: 400 !important;
  color: #333333;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.juzhong {
  text-align: center;
}
.avatar-uploader-icon {
  font-size: 1.75rem;
  color: #8c939d;
  width: 24.125rem;
  height: 15.125rem;
  line-height: 15.125rem;
  text-align: center;
}
.el-icon-circle-close {
  height: 11.125rem !important;
}
.avatar {
  width: 24.125rem;
  height: 15.125rem;
  /* object-fit: cover; */
  display: block;
}
.baohan {
  margin: 0 10%;
  /* height: 100px; */
  display: flex;
  /* justify-content: center; */
  align-items: center;
  /* flex-direction: column; */
  /* padding: 0.5rem 0; */
  border: 0.1rem solid #d9d9d9;
  /* padding-right: 4.375rem; */
  margin-bottom: 1.25rem;
  padding: 10px;
}
.text {
  font-size: 14px;
}

.item {
  padding: 0.325rem 0;
  font-size: 18px;
  padding-left: 1.025rem;
}
.fudons{
  float: left;
}
.box-card {
  width: 480px;
}
.bbcimg {
  width: 100%;
  height: 100px;
}
.contzis {
     position: absolute;
    bottom: 0;
    width: 100%;
    left: 0px;
    font-size: 1rem;
    color: #666666;
    font-size: 16px;
    width: 200px;
}
.contzi {
  position: absolute;
  bottom: 0;
  left: 70px;
    width: 84%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
}
</style>
<style >
.messageasd {
  width: 267px !important;
  margin-top: -15.875rem;
}
.el-message-box__btns {
  padding: 5px 15px 0;
  text-align: center !important;
}
</style>